import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks";
import { useEffect } from "react";
import { LastLoginExpireIn, StoragePathKey } from "../utils/consts";
import moment from "moment-timezone";
import { Loader } from "../components";

let isLoading = false;

export const AuthWrapper = ({ children }: { children: any }) => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");

  useEffect(() => {
    if (!code && !auth.idToken && !isLoading) {
      const lastLoggedTo = window.localStorage.getItem(LastLoginExpireIn);
      if (lastLoggedTo) {
        const now = moment();
        const date = moment(lastLoggedTo);
        if (date.isValid() && date.isAfter(now)) {
          auth.doAuth();
        }
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [auth.idToken]);

  useEffect(() => {
    const openEndPath = () => {
      let path = window.localStorage.getItem(StoragePathKey);
      if (path) {
        window.localStorage.removeItem(StoragePathKey);
        navigate(path);
      } else {
        setSearchParams({});
      }
    };

    if (code && !isLoading) {
      isLoading = true;
      auth.loadTokenByCode(code).then((authResult) => {
        isLoading = false;
        if (authResult) {
          openEndPath();
        } else {
          auth.doAuth();
        }
      });
    }
  }, [navigate, auth, setSearchParams, code]);

  if (code) {
    return <Loader />;
  }

  return children;
};
