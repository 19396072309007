export const AdditionalConfig = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  structuralSharing: false,
  keepPreviousData: false,
  fetchPolicy: "network-only",
};

export const Roles = {
  Connect: ["Access Alpha Connect Service", "Access all connect data"],
  Curate: ["Access Alpha Curate Service", "Access all curate data"],
  Manage: ["Access Alpha Manage Service"],
};

export const Second = 1000;
export const apiCallResolveMargin = 3 * Second;
export const logoutKey = "logout";
export const AccordionTimeSwitch = 5 * Second;
export const SwitcherTimeSwitch = 5 * Second;

export const ButtonText = {
  Products: "Services",
  SingIn: "Sign In",
  Explore: "Explore",
  Details: "Details",
  Close: "Close",
  LogOut: "Log Out",
  Launch: "Launch",
  LandingPageSignIn: "Sign in to view the range of services",
  ComingSoon: "Coming soon",
  Continue: "Continue",
  Register: "Register",
  Update: "Update",
  Cancel: "Cancel",
  MyAccount: "My account",
};

export const HeroSectionText = {
  Headline: "One Platform for All Your Natural Diamond Needs",
  Slogan: "Streamline your operations with our comprehensive platform and powerful exploration tools",
  Products: "Already {number} Products on the Platform",
};

export const FooterLinks = {
  Terms: "Terms & Conditions",
  PrivacyPolicy: "Privacy Policy",
  Cookies: "Our Use of Cookies",
};

export const LogoText = "Explore - Link to home page";

export const FooterCopyright = "De Beers UK Limited (a member of the Anglo American plc group) 2023";

export const ExploreProducts = "Explore Services";

export const ServicesHeader = "Explore";
export const SubcribedServicesHeader = "My Subscriptions";

export const ServiceTypePublic = "Public";

export const SidePanelSubscriptionsText = "Please sign in to your account to view all services";
export const SidePanelEmptySubscriptionsText = "You do not currently have any Okta Integrated Subscriptions";
export const StoragePathKey = "SavePath";
export const StorageVerifierKey = "Verifier";
export const LastLoginExpireIn = "LastLoginExpireIn";

export const ServicesWithoutExpandibleSections = ["image library", "smart system"];

export const Stream = "Stream";

export const WidthSize1024 = 1024;
export const WidthSize1280 = 1280;
export const Width430Size = 430;
export const Width800Size = 800;

export const MyAccountText = {
  MyAccountTitle: "My Account",
  FirstNameTitle: "First name",
  SurNameTitle: "Surname",
  EmailIdTitle: "Email Id",
  DisplayNameTitle: "Display name",
  DisplayNamePlaceholder: "Enter your display name",
};

export const oktasignInWidgetHeader = "Explore De Beers Group Services";
export const signInHeader = "Please sign in";

export const lessCharactersErrorMessage = "Display name must be more than 3 characters!";
export const nonAlphabticalCharctersErrorMessage = "Display name should contains alphabetical characters only!";
export const DisplayNameSuccessMessage = "Display Name updated successfully!";
