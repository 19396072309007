import { ButtonText, Stream } from "../../utils/consts";
import { IconRight } from "..";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { handleLogout, validateAuthentication } from "../../utils/helpers";

export type ServiceBlockProps = {
  id: string;
  name: string;
  image: string;
  description: string;
  isSubscribedService: boolean;
  link: string;
  appName: string;
};

export const ServiceBlock = ({
  id,
  name,
  image,
  description,
  isSubscribedService,
  link,
  appName,
}: ServiceBlockProps) => {
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const navigate = useNavigate();
  const isStreamPublicService = !isSubscribedService && name === Stream;
  const { oktaAuth, authState } = useOktaAuth();

  const getButtonName = () => {
    if (isStreamPublicService) {
      return ButtonText.ComingSoon;
    } else if (isSubscribedService) {
      return ButtonText.Launch;
    } else {
      return ButtonText.Continue;
    }
  };

  const clickHandler = async () => {
    try {
      const isAuthenticated = await validateAuthentication();

      if (!isAuthenticated) {
        await handleLogout(oktaAuth);

        return;
      }

      if (isStreamPublicService) {
        navigate("");
      } else if (isSubscribedService) {
        try {
          if (!authState?.isAuthenticated) {
            window.sessionStorage.clear();
            navigate("/login");
          } else {
            window.open(link, "_blank");
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        navigate("/" + appName);
      }
    } catch {
      console.error("error happened while checking your authentication");
    }
  };

  const serviceMargin = name.length <= 18 && hovered ? "service-top-margin" : "";

  return (
    <div
      className="service-block"
      key={id}
      onClick={clickHandler}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
    >
      <div
        className="image-block"
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className="service-info-block">
        <h5 className="medium uppercase">{name}</h5>
      </div>
      {!isStreamPublicService ? (
        <div className={`service-info-block-text ${serviceMargin}`}>
          <span className="medium">{description}</span>
        </div>
      ) : (
        <div className={`service-info-block-text `}>
          <span className="medium">{}</span>
        </div>
      )}
      <div className="button-wrapper">
        <button className="btn-primary-blue btn-wide" disabled={isStreamPublicService}>
          {getButtonName()} {!isStreamPublicService && <IconRight />}
        </button>
      </div>
    </div>
  );
};
