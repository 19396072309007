import { useNavigate, useParams } from "react-router-dom";
import { useGetPlatforms } from "../../queries/useGetApps";
import {
  FooterWrapper,
  Header,
  Loader,
  SidePanelPlatformsWrapper,
  SidePanelSubscriptions,
  SidePanelWrapper,
  TabItem,
} from "../../components";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Platform } from "../../utils/models";
import { Size1024 } from "./partial/size1024";
import { SizeNormal } from "./partial/sizeNormal";
import { WidthSize1024 } from "../../utils/consts";
import { filterPlatformsByVisible, getFilteredServices } from "../../utils/helpers";
import { useAuth } from "../../hooks";
import { useGetMySubscriptions } from "../../queries/useGetMySubscriptions";
import { useOktaAuth } from "@okta/okta-react";

export const Template = () => {
  const { appName } = useParams();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const { data: mySubscriptions } = useGetMySubscriptions();
  const { isLoading, isError, data } = useGetPlatforms();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [size, setSize] = useState(1280);
  const [isHeaderDark, setIsHeaderDark] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsHeaderDark(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const calculateSize = () => {
      setSize(window.innerWidth);
    };
    calculateSize();
    window.addEventListener("resize", calculateSize);
    return () => {
      window.removeEventListener("resize", calculateSize);
    };
  }, []);

  if (isLoading || isError || !Array.isArray(data)) {
    return <Loader hasError={isError} />;
  }
  const visiblePlatforms = !authState?.isAuthenticated ? filterPlatformsByVisible(data) : data;

  const page = visiblePlatforms.find((platform) => platform.appName.toString() === appName);

  const nonSubscribedServices = getFilteredServices(visiblePlatforms, mySubscriptions!);

  if (!page) {
    navigate("/");
  }

  const handleClickOpenSidePanel = () => {
    setShowSidePanel(!showSidePanel);
  };

  const tabSwitcherData: TabItem[] = [
    {
      title: "My subscriptions",
      content: <SidePanelSubscriptions />,
      hide: !authState!.accessToken?.accessToken,
    },
    {
      title: "All services",
      content: <SidePanelPlatformsWrapper onClose={() => setShowSidePanel(false)} data={nonSubscribedServices} />,
    },
  ];

  return (
    <>
      <Container fluid className={`header-container dark`}>
        <Header handleClickOpenSidePanel={handleClickOpenSidePanel} />
      </Container>
      {size <= WidthSize1024 ? <Size1024 page={page as Platform} /> : <SizeNormal page={page as Platform} />}
      {showSidePanel && (
        <SidePanelWrapper
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          tabSwitcherData={tabSwitcherData}
          selectedTab={!authState?.isAuthenticated ? selectedTab + 1 : selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
      <FooterWrapper isTemplate={true} />
    </>
  );
};
