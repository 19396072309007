import { useNavigate } from "react-router-dom";
import { Index } from "../../../screens/Index";
import { FooterWrapper } from "../../FooterWrapper";
import { useAuth } from "../../../hooks";
import { useOktaAuth } from "@okta/okta-react";

export const Privacy = () => {
  const auth = useAuth();
  const { authState } = useOktaAuth();
  var policyHeading = "policy-headingPP1";
  if (authState !== null) {
    policyHeading = !authState.accessToken?.accessToken ? "policy-headingPP1" : "policy-headingPP2";
  }
  const navigate = useNavigate();
  return (
    <>
      <Index isLandingPage={false} />
      <div className="container text-container">
        <h3 className={`light center ${policyHeading}`}>Privacy Policy</h3>
        <p>PRIVACY NOTICE</p>
        <p>
          De Beers UK Limited WEBSITE PRIVACY NOTICE Our Site (“Our Site”) is operated by De Beers UK Limited
          ("Company", “we” or “us” or “our”). We are registered in England and Wales under company number 02054170 and
          have our registered office at 17 Charterhouse Street, London, EC1N 6RA, UK. We are committed to protecting and
          respecting your privacy and we comply with data protection legislation and other applicable local laws.
        </p>
        <p>
          The Company is a member of the De Beers Group of Companies ("Group"). The Group is made up of different legal
          entities, including:
        </p>
        <p>De Beers Diamond Jewellers Limited</p>
        <p>De Beers Auction Sales Belgium NV</p>
        <p>Forevermark Italy S.r.l.</p>
        <p>Forevermark Limited (UK)</p>
        <p>Forevermark NV</p>
        <p>Forevermark Marketing (Shanghai) Limited</p>
        <p>Element Six (UK) Limited</p>
        <p>Element Six AB</p>
        <p>Element Six B.V</p>
        <p>Element Six GmbH</p>
        <p>Element Six Limited</p>
        <p>Element Six Limited (Ireland)</p>
        <p>Element Six Technologies Limited</p>
        <p>
          You can find out more about our Group at{" "}
          <a href="https://www.debeersgroup.com" target="_blank" rel="noreferrer">
            https://www.debeersgroup.com
          </a>{" "}
          or by contacting us using the information in the contact us section.
        </p>
        <p>
          This privacy notice (together with our Terms and Conditions which can be found here:{" "}
          <button className="btn-tnc" onClick={() => navigate("/termsconditions")}>
            https://explore.debeersgroup.com/termsconditions
          </button>{" "}
          and any other documents referred to in it) sets out how we will use any personal data which you provide
          through our Site. Please read it carefully before you proceed. Unless you have carefully reviewed and
          completely understand all the contents in this privacy notice, you will not be entitled to access or use Our
          Site or any services therein.
        </p>
        <p>UPDATES</p>
        <p>
          We may amend this privacy notice from time to time to keep it up to date with legal requirements and the way
          we operate our business and will place any updates on this webpage. Please regularly check these pages for the
          latest version of this notice. If we make fundamental changes to this privacy notice, we will seek to inform
          you by notice on our Site or email where possible. This privacy notice was last updated on 1 April 2023.
        </p>
        <p>THIRD PARTY WEBSITES</p>
        <p>
          You might find external links to third party websites on our Site. This privacy notice does not apply to your
          use of a third-party site. We are not responsible for the privacy practices of such third-party websites.
        </p>
        <p>WHAT PERSONAL DATA WE COLLECT AND WHEN AND WHY WE USE IT</p>
        <p>In this section you can find out more about</p>
        <p>• the types of personal data we collect.</p>
        <p>• when we collect personal data</p>
        <p>• how we use personal data</p>
        <p>• the legal basis for using personal data.</p>
        <p>WHEN WE COLLECT PERSONAL DATA</p>
        <p>
          Personal data is any information capable of identifying a natural person, directly or indirectly, by reference
          to an identification number or to one or more factors specific to their his or her physical, physiological,
          mental, economic, cultural or social identity. Data is considered personal when it enables anyone to link
          information to a specific person, even if the person or entity holding that data cannot make that link.
        </p>
        <p>
          We collect personal data about you collectively ("you") if you register with or use our Site or request our
          services.
        </p>
        <p>Our Site is not intended for children, and we do not knowingly collect data relating to children.</p>
        <p>If you request our services</p>
        <p>
          If you engage our services through our Site, we will process your personal data in order to provide you with
          relevant training courses.
        </p>
        <p>
          The personal data that we process to do so will be the personal data that you provide to us, such as your name
          and your contact details (such as your email address).
        </p>
        <p>
          The source of your personal data will be you, however, we may also generate personal data internally if this
          is needed to manage the training courses. You hereby authorize us to generate personal data for such purpose.
        </p>
        <p>If you contact us using the enquiry form on our Site</p>
        <p>
          If you contact us using the enquiry form, we will process your personal data in order to consider and respond
          to those queries.
        </p>
        <p>
          The personal data that we process to do so will be the personal data that you provide to us, such as your
          name, your contact details (such as email address) and the content of your query.
        </p>
        <p>
          The source of your personal data will be you, however, we may also generate personal data internally if this
          is needed to respond to your query. You hereby authorize us to generate personal data for such purpose.
        </p>
        <p>COOKIES</p>
        <p>
          If you visit our Site, we use cookies to understand who has seen which webpages, to determine how frequently
          particular pages are visited and to determine the most popular areas of our Site.
        </p>
        <p>
          Cookies will collect personal data about you. The personal data that is collected by these cookies is internet
          protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform and other technology on the devices you use to access our Site.
        </p>
        <p>
          The source of this personal data will be you, gathered by the cookie. For further information, please see the
          Cookies section below.
        </p>
        <p>
          We also collect, use and share aggregated data such as statistical or demographic data for any purpose.
          Aggregated data may be derived from your personal data but is not considered personal data in law as this data
          does not directly or indirectly reveal your identity. For example, we may aggregate your usage data to
          calculate the percentage of users accessing a specific website feature. However, if we combine or connect
          Aggregated data with your personal data so that it can directly or indirectly identify you, we treat the
          combined data as personal data which will be used in accordance with this privacy notice.
        </p>
        <p>
          We do not collect any Special Categories of Personal Data about you (this includes details about your race or
          ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union
          membership, information about your health and genetic and biometric data). Nor do we collect any information
          about criminal convictions and offences.
        </p>
        <p>LEGAL BASIS FOR USING YOUR PERSONAL DATA</p>
        <p>
          We will only collect, use and share your personal data where we are satisfied that we have an appropriate
          legal basis to do this. This may be because:
        </p>
        <p>
          we need to use your personal data to perform a contract or take steps to enter into a contract with you;
          and/or
        </p>
        <p>
          we need to use your personal data for our legitimate interest as a commercial organisation. In all such cases,
          we will always look after your information in a way that is proportionate and respects your privacy rights and
          you have a right to object to processing as explained in the Legal Rights section below; and/or
        </p>
        <p>
          we need to use your personal data to comply with a relevant legal or regulatory obligation that we have;
          and/or
        </p>
        <p>We have your consent to using your personal data for a particular activity.</p>
        <p></p>
        <p>In particular:</p>
        <table className="spec-table">
          <thead>
            <tr>
              <th>What we use your information for</th>
              <th>Legal basis for using personal data</th>
              <th>Legitimate interests legal basis</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>To register your account</td>
              <td>Performance of a contract</td>
              <td>Not applicable</td>
            </tr>
            <tr>
              <td>
                To manage our relationship with you which will include: (a) notifying you about changes to our terms or
                privacy policy (b) Asking you to leave a review or take a survey
              </td>
              <td>Performance of a contract Legitimate interests</td>
              <td>To keep our records updated and to study how individuals use our products/services</td>
            </tr>
            <tr>
              <td>To consider and respond to queries that you submit using the "contact us" section of our Site</td>
              <td>Legitimate interests</td>
              <td>The management of our business</td>
            </tr>
            <tr>
              <td>To manage our Site and improve your user experience</td>
              <td>Legitimate interests</td>
              <td>
                To ensure the effective operation of our Site. To ensure that content from our Site is presented in the
                most effective manner for you and for your computer.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          If you would like to find out more about the legal basis for which we process personal data, please contact us
          at <a href="mailto:contactstream@debeersgroup.com">contactstream@debeersgroup.com</a>
        </p>
        <p></p>

        <p>COOKIES</p>
        <p>
          A cookie is a small text file containing small amounts of information which is downloaded to / stored on your
          computer (or other internet enabled devices, such as a smartphone or tablet) when you visit a website. Cookies
          help us remember information about your visit to our Site, like your country, language and other settings.
          They can also help us to operate our Site more efficiently and make your next visit easier. Cookies can allow
          us to do various other things, as explained further in our Cookie Policy which you can access by clicking on
          the link below.
        </p>
        <p>
          For more information about how our cookies work and information about how to manage your cookie settings
          please view our Cookie Policy below.
        </p>
        <p></p>
        <p>HOW WE PROTECT AND STORE YOUR INFORMATION</p>
        <p>SHARING PERSONAL DATA OTHERS</p>
        <p>In this section you can find out more about how we share personal data:</p>
        <p>within Company</p>
        <p>with third parties that help us provide our products and services; and</p>
        <p>government organisations and agencies, law enforcement and regulators</p>
        <p>We share your personal data in the manner and for the purposes described below:</p>
        <p>
          within the Group, where such disclosure is necessary to provide you with our products or services or to manage
          our business.
        </p>
        <p>with our third parties who help manage our business and deliver our services;</p>
        <p>
          with government organisations and agencies, law enforcement, supervisory authorities and regulators, which may
          include the Information Commissioner's Office or the Cyberspace Administration of China, to comply with all
          applicable laws, regulations and rules, and requests of law enforcement, regulatory and other governmental
          agencies; and
        </p>
        <p>
          we may share in aggregate, statistical form, non‑personal data regarding the visitors to our Site, traffic
          patterns, and website usage with our partners, affiliates or advertisers.
        </p>
        <p>
          Where appropriate, the third parties set out above have agreed to confidentiality restrictions and use any
          personal data we share with them or which they collect on our behalf solely for the purpose of providing the
          contracted service to us.
        </p>
        <p>
          If, in the future, we sell or transfer some or all of our business or assets to a third party, we may disclose
          information to a potential or actual third-party purchaser of our business or assets.
        </p>
        <p></p>
        <p>SECURITY</p>
        <p>
          We have implemented and maintain appropriate technical and organisational security measures, policies and
          procedures designed to reduce the risk of accidental destruction or loss, or the unauthorised disclosure or
          access to such information appropriate to the nature of the information concerned.
        </p>
        <p>
          Where we have given you (or where you have chosen) a password which enables you to access certain parts of our
          Site, you are responsible for keeping this password confidential. We ask you not to share a password with
          anyone.
        </p>
        <p>
          While we have implemented reasonable technical and organisational precautions to protect the security and
          integrity of personal data provided to our Site, due to the inherent nature of the internet as an open global
          communications vehicle, we cannot guarantee that information, during transmission through the internet or
          while stored on our system or otherwise in our care, will be safe from intrusion by others, such as hackers.
          We maintain physical, electronic and procedural safeguards to protect your Personal Data. You agree to (a)
          immediately notify us of any unauthorised use of your password or account or any other breach of security, and
          (b) ensure that you exit from your account at the end of each session. It is your responsibility to control
          the dissemination and use of your password, control access to and use of your account, and notify us when you
          desire to cancel your account on this Site. To the extent permitted by law, we are not responsible or liable
          for any loss or damage arising from your failure to comply with this provision.
        </p>
        <p>
          We will not be liable for disclosure of your information due to errors or unauthorised acts of third parties
          during or after transmission.
        </p>
        <p>
          We recommend that you change your password every three months. If you are using a public computer (e.g., at a
          library), or a shared computer, we urge you to log out of your account and our Site altogether and quit the
          browser application before you leave the computer. This will help prevent others from accessing your account
          and any personal information.
        </p>
        <p>
          In the unlikely event that we believe that the security of your personal information in our possession or
          control may have been compromised, we may seek to notify you of that development. If a notification is
          appropriate, we would endeavour to do so as promptly as possible under the circumstances, and, to the extent
          we have your e-mail address, we may notify you by e-mail.
        </p>
        <p></p>
        <p>STORING YOUR PERSONAL DATA</p>
        <p>
          We will store your personal data for as long as is reasonably necessary for the purposes for which it was
          collected, as explained in this privacy notice. Where your information is no longer needed, we will ensure
          that it is disposed of in a secure manner. In some circumstances we may store your personal data for longer
          periods of time, for instance where we are required to do so in accordance with legal, regulatory, tax,
          accounting requirements.
        </p>
        <p>
          In specific circumstances we may store your personal data for longer periods of time so that we have an
          accurate record of your dealings with us in the event of any complaints or challenges, or if we reasonably
          believe there is a prospect of litigation relating to your personal data or dealings.
        </p>
        <p></p>
        <p>LEGAL RIGHTS AVAILABLE TO HELP MANAGE YOUR PRIVACY</p>
        <p>
          Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking,
          you have certain rights in relation to your personal data.
        </p>
        <p></p>
        <p>TO ACCESS PERSONAL DATA</p>
        <p>
          You have a right to request that we provide you with a copy of your personal data that we hold and you have
          the right to be informed of; (a) the source of your personal data; (b) the purposes, legal basis and methods
          of processing; (c) the data controller’s identity; and (d) the entities or categories of entities to whom your
          personal data may be transferred.
        </p>
        <p></p>
        <p>TO RECTIFY / ERASE PERSONAL DATA</p>
        <p>
          You have a right to request that we rectify inaccurate personal data. We may seek to verify the accuracy of
          the personal data before rectifying it.
        </p>
        <p>You can also request that we erase your personal data in limited circumstances where:</p>
        <p>it is no longer needed for the purposes for which it was collected; or</p>
        <p>you have withdrawn your consent (where the data processing was based on consent); or</p>
        <p>following a successful right to object (see right to object); or</p>
        <p>it has been processed unlawfully; or</p>
        <p>to comply with a legal obligation to which the Company is subject.</p>
        <p>
          To the extent permitted by law, we are not required to comply with your request to erase personal data if the
          processing of your personal data is necessary:
        </p>
        <p>for compliance with a legal obligation; or</p>
        <p>for the establishment, exercise or defence of legal claims</p>
        <p></p>
        <p>RIGHT TO RESTRICT THE PROCESSING OF YOUR PERSONAL DATA</p>
        <p>You can ask us to restrict your personal data, but only where:</p>
        <p>its accuracy is contested, to allow us to verify its accuracy; or</p>
        <p>the processing is unlawful, but you do not want it erased; or</p>
        <p>
          it is no longer needed for the purposes for which it was collected, but we still need it to establish,
          exercise or defend legal claims; or
        </p>
        <p>you have exercised the right to object, and verification of overriding grounds is pending.</p>
        <p>We can continue to use your personal data following a request for restriction, where:</p>
        <p>we have your consent; or</p>
        <p>to establish, exercise or defend legal claims; or</p>
        <p>to protect the rights of another natural or legal person.</p>
        <p></p>
        <p>RIGHT TO TRANSFER YOUR PERSONAL DATA</p>
        <p>
          You can ask us to provide your personal data to you in a structured, commonly used, machine‑readable format,
          or you can ask to have it transferred directly to another data controller, but in each case only where:
        </p>
        <p>the processing is based on your consent or on the performance of a contract with you; and</p>
        <p>the processing is carried out by automated means.</p>
        <p></p>
        <p>RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA</p>
        <p>
          You can object to any processing of your personal data which has our legitimate interests as its legal basis,
          if you believe your fundamental rights and freedoms outweigh our legitimate interests.
        </p>
        <p>
          If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests
          which override your rights and freedoms.
        </p>
        <p></p>
        <p>RIGHT TO OBJECT TO HOW WE USE YOUR PERSONAL DATA FOR DIRECT MARKETING PURPOSES</p>
        <p>You can request that we change the way we contact you for marketing purposes.</p>
        <p>
          You can request that we not transfer your personal data to unaffiliated third parties for the purposes of
          direct marketing or any other purposes.
        </p>
        <p></p>
        <p>RIGHT TO OBTAIN A COPY OF PERSONAL DATA SAFEGUARDS USED FOR TRANSFERS OUTSIDE YOUR JURISDICTION</p>
        <p>
          You can ask to obtain a copy of, or reference to, the safeguards under which your personal data is transferred
          outside of the European Union.
        </p>
        <p>We may redact data transfer agreements to protect commercial terms.</p>
        <p></p>
        <p>RIGHT TO LODGE A COMPLAINT WITH YOUR LOCAL SUPERVISORY AUTHORITY</p>
        <p>
          You have a right to lodge a complaint with your local supervisory authority if you have concerns about how we
          are processing your personal data. We ask that you please attempt to resolve any issues with us first,
          although you have a right to contact your supervisory authority at any time.
        </p>
        <p>
          If you wish to access any of the above-mentioned rights, we may ask you for additional information to confirm
          your identity and for security purposes, in particular before disclosing personal data to you. We reserve the
          right to charge a fee where permitted by law, for instance if your request is manifestly unfounded or
          excessive. You can exercise your rights by contacting us. Subject to legal and other permissible
          considerations, we will make every reasonable effort to honour your request promptly or inform you if we
          require further information in order to fulfil your request.
        </p>
        <p>
          We may not always be able to fully address your request, for example if it would impact the duty of
          confidentiality we owe to others, or if we are legally entitled to deal with the request in a different way.
        </p>
        <p></p>
        <p>CONTACT US</p>
        <p>
          The primary point of contact for all issues arising from this privacy notice is our Data Protection Team. The
          Data Protection Team can be contacted in the following ways:
        </p>
        <p>
          Email address: <a href="mailto:dataprotection@angloamerican.com">dataprotection@angloamerican.com</a>
        </p>
        <p>Telephone number: +44 (0)20 7968 8888</p>
        <p>Postal address: 17 Charterhouse Street, London, EC1N 6RA</p>
        <p>
          If you have any questions, concerns or complaints regarding our compliance with this privacy notice, the
          information we hold about you or if you wish to exercise your rights, we encourage you to first contact our
          Data Protection Team. We will investigate and attempt to resolve complaints and disputes and make every
          reasonable effort to honour your wish to exercise your rights as quickly as possible and, in any event, within
          the timescales provided by data protection laws.
        </p>
        <p></p>
        <p>TO CONTACT YOUR DATA PROTECTION SUPERVISORY AUTHORITY</p>
        <p>
          You have a right to lodge a complaint with your local data protection supervisory authority (i.e. the
          regulatory authority in your place of habitual residence, place or work or place of alleged infringement) at
          any time. We ask that you please attempt to resolve any issues with us before your local supervisory
          authority.
        </p>
      </div>
      <FooterWrapper />
    </>
  );
};
