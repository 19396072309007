import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { Routes, Route, useNavigate, To } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.scss";
import { AuthWrapper } from "./layouts/auth";
import { routes } from "./routes";
import { AuthProvider } from "./hooks";
// import { OktaAuthProvider } from "./hooks/OktaAuth";
import { useEffect } from "react";
import { Second } from "./utils/consts";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Config from "../src/config/okta_config";
// import { AuthOktaWrapper } from "./layouts/oktaAuth";

function App() {
  const queryClient = new QueryClient();
  const navigate = useNavigate();
  const oktaAuth = new OktaAuth(Config.widget);
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: To) => {
    navigate(originalUri, { replace: true });
  };
  useEffect(() => {
    // add functionality for OneTrust background
    const close = () => {
      const closeButton = document.querySelector("#close-pc-btn-handler");
      if (closeButton) {
        (closeButton as any).click();
      }
    };
    // waiting initialization
    setTimeout(() => {
      const background = document.querySelector(".onetrust-pc-dark-filter");
      background?.addEventListener("click", close);
    }, Second);

    return () => {
      const background = document.querySelector(".onetrust-pc-dark-filter");
      background?.removeEventListener("click", close);
    };
  });
  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Container fluid>
              <Routes>
                {routes.map((item, index) => {
                  const Component = item.component;
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      element={
                        <AuthWrapper>
                          <Component />
                        </AuthWrapper>
                      }
                    />
                  );
                })}
              </Routes>
            </Container>
          </AuthProvider>
        </QueryClientProvider>
      </Security>
    </div>
  );
}

export default App;
