import { useEffect, useRef } from "react";

export const VideoBlock = ({ isActive, path }: { isActive: boolean; path: string }) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    const video: any = ref?.current;
    if (video) {
      if (isActive) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [isActive]);

  return (
    <div className={`image ${isActive ? "active" : ""}`}>
      <video ref={ref} muted controls >
        <source src={path} type="video/mp4" />
      </video>
    </div>
  );
};
