import { useOktaAuth } from "@okta/okta-react";
import { IconRightGrey } from "../GlobalIcons";
import { handleLogout, validateAuthentication } from "../../utils/helpers";

type SidePanelPlatformProps = {
  id: string;
  name: string;
  image: string;
  onClick?: (name: string) => void;
  path?: string;
  appName: string;
};

export const SidePanelPlatform = ({ id, name, image, onClick, path, appName }: SidePanelPlatformProps) => {
  const { oktaAuth } = useOktaAuth();

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    try {
      const isAuthenticated = await validateAuthentication();

      if (!isAuthenticated) {
        await handleLogout(oktaAuth);

        return;
      }

      handleNavigate(e);
    } catch {
      console.error("error happened while checking your authentication");
    }
  };

  const handleNavigate = (e: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(appName);
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    window.open(path, "_blank", "noopener,noreferrer");
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <a
      href={path || "/"}
      target="_blank"
      rel="noreferrer"
      className="side-platform-item"
      key={id}
      onClick={handleClick}
    >
      <img src={image} alt={name} />
      <div className="body-large medium">{name}</div>
      <div className="icon">
        <IconRightGrey />
      </div>
    </a>
  );
};
