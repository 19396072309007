import { IconClose, SidePanelLoginForm, TabItem, TabSwitcher } from "..";
import { ButtonText } from "../../utils/consts";
import { useAuth } from "../../hooks";
import { useOktaAuth } from "@okta/okta-react";

type SidePanelProps = {
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  tabSwitcherData: TabItem[];
  selectedTab: number;
  setSelectedTab: (value: number) => void;
};
export const SidePanel = ({
  showSidePanel,
  setShowSidePanel,
  tabSwitcherData,
  selectedTab,
  setSelectedTab,
}: SidePanelProps) => {
  const auth = useAuth();
  const { authState } = useOktaAuth();

  return (
    <>
      <div className="side-panel-overlay" onClick={() => setShowSidePanel(false)}>
        <div
          className={`side-panel ${showSidePanel ? "visible" : ""} ${!authState!.accessToken ? "add-padding" : ""}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <div className="close" onClick={() => setShowSidePanel(false)}>
            <div className="icon-text-block">
              <IconClose /> {ButtonText.Close}
            </div>
          </div>
          <TabSwitcher data={tabSwitcherData} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          {!authState?.accessToken && <SidePanelLoginForm />}
        </div>
      </div>
    </>
  );
};
