import { SidePanel, TabItem } from "..";

type SidePanelWrapperProps = {
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  tabSwitcherData: TabItem[];
  selectedTab: number;
  setSelectedTab: (value: number) => void;
};
export const SidePanelWrapper = ({
  showSidePanel,
  setShowSidePanel,
  tabSwitcherData,
  selectedTab,
  setSelectedTab,
}: SidePanelWrapperProps) => {
  return (
    <SidePanel
      showSidePanel={showSidePanel}
      setShowSidePanel={setShowSidePanel}
      tabSwitcherData={tabSwitcherData}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
};
