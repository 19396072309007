import { Index } from "./screens/Index";
import { Page404 } from "./screens/404";
import { Template } from "./screens/template";
import { MyAccount } from "./screens/MyAccount";
import { TnC } from "./components/Footer/ModalContent/TnC";
import { Privacy } from "./components/Footer/ModalContent/Privacy";
import { Cookies } from "./components/Footer/ModalContent/Cookies";
import { Login } from "./screens/Login";

export const routes: { path: string; component: any }[] = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/:appName",
    component: Template,
  },
  {
    path: "/termsconditions",
    component: TnC,
  },
  {
    path: "/privacypolicy",
    component: Privacy,
  },
  {
    path: "/cookies",
    component: Cookies,
  },
  {
    path: "*",
    component: Page404,
  },
  {
    path: "/myaccount",
    component: MyAccount,
  },
  {
    path: "/login",
    component: Login,
  },
];
