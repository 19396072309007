import { Accordion } from "react-bootstrap";
import { AccordionItem } from "../../utils/models";
import { IconRight } from "../GlobalIcons";
import { VideoWrapper } from "../VideoWrapper";

export type AccordionListProps = {
  data: AccordionItem[];
};

export const AccordionList = ({ data }: AccordionListProps) => {
  return (
    <Accordion className={"accordion-list"} defaultActiveKey="0" flush >
      {data.map((item, index) => (
        <Accordion.Item
          key={index}
          eventKey={index.toString()}
        >
          <Accordion.Header>{item.title}</Accordion.Header>
          <Accordion.Body>
            <>
            {item.description.split("\n").map((item, index) => (
              <p key={index}>{item}</p>
            ))}
            {item.video && <VideoWrapper videoSrc={item.video} thumbnail={item.thumbnail} />}
            { item.buttonInfo.length > 0 && item.buttonInfo.map((singleButtonInfo, index) => {
              return <div key={index}>
              <h5>{singleButtonInfo.subheading}</h5>
              <p>{singleButtonInfo.subheadingText}</p>
              {singleButtonInfo.link && singleButtonInfo.text && <p className="accordion-link">
                <a className="btn-accordion" target="_blank" href={singleButtonInfo.link} rel="noreferrer">{singleButtonInfo.text} <IconRight /></a>
              </p>}
              </div>
            })
            }
            </>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
