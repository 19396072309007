import React, { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import OktaSignInWidget from "../../OktaSignInWidget";
import { useOktaAuth } from "@okta/okta-react";
import "../../styles/screens/login.scss";
import { FooterWrapper, Loader } from "../../components";
import { oktasignInWidgetHeader, signInHeader } from "../../utils/consts";

export interface okta_config {
  config: any;
}

export const Login = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [isSigninTextAppended, setIsSigninTextAppended] = useState(false);
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo(info as any);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onSuccess = (tokens: any) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err: any) => {
    console.log("Sign in error:", err);
  };

  if (!authState) {
    return <Loader />;
  }
  const handleClickOpenSidePanel = () => {
    setShowSidePanel(!showSidePanel);
  };
  setTimeout(function () {
    const txt = document.createElement("div");
    txt.innerHTML = oktasignInWidgetHeader;
    txt.classList.add("subtext");
    txt.classList.add("opacity-back");
    const beaconcontainer = document.createElement("div");
    beaconcontainer.setAttribute("data-type", "beacon-container");
    beaconcontainer.classList.add("beacon-container");
    const header = document.querySelector(".okta-sign-in-header");
    if (header) {
      header.innerHTML = "";
      header.append(beaconcontainer);
      header.appendChild(txt);
    }
  }, 100);
  setInterval(function () {
    const signinHeader = document.querySelector(".okta-form-title");
    if (signinHeader) {
      signinHeader.textContent = signInHeader;
    }
  }, 10);

  setInterval(function () {
    const list = document.querySelectorAll("input");
    list.forEach(function (el) {
      if (
        ["text", "number", "email", "password", "phone"].includes(el.getAttribute("type")!) &&
        !el.getAttribute("debeers-state")
      ) {
        el.setAttribute("placeholder", "");
        el.addEventListener("focus", function () {
          try {
            const label = el?.parentElement?.parentElement?.parentElement?.childNodes[0].childNodes[0] as HTMLElement;
            label.classList.add("focused");
          } catch (e) {
            console.log("focus: label not found", e);
          }
        });
        el.addEventListener("blur", function () {
          try {
            if (el.value === "") {
              const label = el?.parentElement?.parentElement?.parentElement?.childNodes[0].childNodes[0] as HTMLElement;
              label.classList.remove("focused");
            }
          } catch (e) {
            console.log("blur: label not found", e);
          }
        });
        el.setAttribute("debeers-state", "yes");
      }
    });
  }, 10);

  return (
    <>
      <div id="login-bg-image-id" className="login-bg-image"></div>
      {authState?.isAuthenticated ? (
        <Navigate to={{ pathname: "/" }} />
      ) : (
        <OktaSignInWidget onSuccess={onSuccess} onError={onError} />
      )}
      <FooterWrapper isLogin={true} />
    </>
  );
};
