import { FooterCopyright, FooterLinks } from "../../utils/consts";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="links">
        <div className="single-link" onClick={() => navigate("/termsconditions")}>
          {FooterLinks.Terms}
        </div>
        <div className="divider">&#124;</div>
        <div className="single-link" onClick={() => navigate("/privacypolicy")}>
          {FooterLinks.PrivacyPolicy}
        </div>
        <div className="divider">&#124;</div>
        <div className="single-link" onClick={() => navigate("/cookies")}>
          {FooterLinks.Cookies}
        </div>
      </div>
      <div className="copyright caption-small heavy">&#169; {FooterCopyright}</div>
    </div>
  );
};
