import { Link, useNavigate } from "react-router-dom";

export const Loader = ({ hasError }: { hasError?: boolean }) => {
  const navigate = useNavigate();
  return (
    <div className="loader-wrapper">
      <span className="loader" />
      {hasError && (
        <div className={"text-center error-message"}>
          <div>
            Something went wrong...
            <br />
            Please, try again later.
          </div>
          <Link to="#" onClick={() => navigate(-1)}>
            Back
          </Link>
        </div>
      )}
    </div>
  );
};
