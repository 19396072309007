import { CustomCarousel, ServiceBlock } from "..";
import { Platform, Subscription } from "../../utils/models";

export type ServicesListProps = {
  data: Platform[] | Subscription[];
  isSubscribedService: boolean;
};

export const ServicesList = ({ data, isSubscribedService }: ServicesListProps) => {
  return (
    <CustomCarousel singleBoxWidth={275}>
      {data.map((singleService, index) => (
        <ServiceBlock
          description={singleService?.template?.title}
          key={index}
          id={singleService.id}
          name={singleService.name}
          image={singleService.image}
          link={singleService.link}
          isSubscribedService={isSubscribedService}
          appName={singleService.appName}
        />
      ))}
    </CustomCarousel>
  );
};
