import { ReactComponent as Logo } from "../../assets/icons/LOGO.svg";
import { ReactComponent as DeBeersLogoIcon } from "../../assets/icons/deBeers.svg";
import { ReactComponent as Down } from "../../assets/icons/chevron-down.svg";
import { ReactComponent as Load } from "../../assets/icons/Loading.svg";
import { ReactComponent as Right } from "../../assets/icons/right.svg";
import { ReactComponent as RightGrey } from "../../assets/icons/rightGrey.svg";
import { ReactComponent as Services } from "../../assets/icons/services.svg";

import { ReactComponent as ConfirmWhite } from "../../assets/icons/confirmWhite.svg";
import { ReactComponent as CarouselRight } from "../../assets/icons/CarouselRight.svg";
import { ReactComponent as CarouselLeft } from "../../assets/icons/CarouselLeft.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as LogOut } from "../../assets/icons/logout.svg";

export const DeBeersLogoRound = () => {
  return <Logo />;
};
export const DeBeersLogo = () => {
  return <DeBeersLogoIcon />;
};
export const IconDown = () => {
  return <Down />;
};
export const IconLoading = () => {
  return <Load />;
};
export const IconRight = () => {
  return <Right />;
};
export const IconRightGrey = () => {
  return <RightGrey />;
};
export const IconServices = () => {
  return <Services />;
};

export const IconConfirm = () => {
  return <ConfirmWhite />;
};

export const CarouselNext = () => {
  return <CarouselRight />;
};
export const CarouselPrev = () => {
  return <CarouselLeft />;
};
export const IconClose = () => {
  return <Close />;
};
export const IconLogOut = () => {
  return <LogOut />;
};
