type VideoWrapperProps = {
  videoSrc: string;
  thumbnail: string
};

export const VideoWrapper = ({ videoSrc, thumbnail }: VideoWrapperProps) => {
  return (
    <video width="100%" muted controls poster={thumbnail}>
      <source src={videoSrc} type="video/mp4" />
    </video>
  );
};
