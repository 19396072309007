import React, { useEffect, useRef } from "react";
import OktaSignIn, { WidgetOptions } from "@okta/okta-signin-widget";
import "../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import oktaConfig from "./config/okta_config";
import { convertKeysToBoolean } from "./utils/helpers";

interface oktaSignInWidgetProps {
  onSuccess: (token: any) => void;
  onError: (error: any) => void;
}

type KeysToConvert = Extract<keyof WidgetOptions, "useInteractionCodeFlow" | "useClassicEngine">[];

export const oktaConfigKeysToConvertBoolean: KeysToConvert = ["useInteractionCodeFlow", "useClassicEngine"];

const OktaSignInWidget: React.FC<oktaSignInWidgetProps> = ({ onSuccess, onError }) => {
  const widgetRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const convertedWidgetConfig = convertKeysToBoolean(
      oktaConfig.widget as unknown as WidgetOptions,
      oktaConfigKeysToConvertBoolean,
    );

    const widget = new OktaSignIn(convertedWidgetConfig);

    // Search for URL Parameters to see if a user is being routed to the application to recover password
    const searchParams = new URL(window.location.href).searchParams;
    (widget as any).otp = searchParams.get("otp");
    (widget as any).state = searchParams.get("state");
    widget
      .showSignInToGetTokens({
        el: "#okta-container",
      })
      .then(onSuccess)
      .catch(onError);

    return () => {
      widget.remove();
    };
  }, [onSuccess, onError]);

  return <div id="okta-container" ref={widgetRef} />;
};

export default OktaSignInWidget;
