import { Platform, PlatformRaw, Subscription, SubscriptionRaw } from "./models";

export const serializePlatform = (item: PlatformRaw): Platform => ({
  id: item.order.toString(),
  appName: item.appName,
  name: item.name,
  icon: item.icon,
  image: item.image,
  link: item.link,
  template: item.template,
  orggroup: item.orggroup,
  description: item.template.description,
  registerlink: item.registerlink
});

export const serializeSubscription = (item: SubscriptionRaw): Subscription => ({
  id: item.order.toString(),
  appName: item.appName,
  name: item.name,
  image: item.image,
  description: item.template.description,
  link: item.link,
  template: item.template,
});
