export type TabItem = {
  title: string;
  content: any;
  hide?: boolean;
};

type TabSwitcherProps = {
  data: TabItem[];
  selectedTab: number;
  setSelectedTab: (value: number) => void;
};

export const TabSwitcher = ({ data, selectedTab, setSelectedTab }: TabSwitcherProps) => {
  const tabs = data.filter((item) => !item?.hide);
  return (
    <>
      <div className="tab-switcher">
        {tabs.length > 1 &&
          tabs.map(({ title }, index) => {
            const active = index === selectedTab;
            let classNames = "tab-header caption-large-upper medium";
            if (active) {
              classNames += " active heavy";
            }
            return (
              <div key={index} className={classNames} onClick={() => setSelectedTab(index)}>
                {title}
              </div>
            );
          })}
      </div>
      <div className="tab-switcher-content">{data[selectedTab].content}</div>
    </>
  );
};
