import { useEffect, useState, ChangeEvent, useCallback, useMemo } from "react";
import { useAuth } from "../../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { useGetPlatforms } from "../../queries/useGetApps";
import {
  Header,
  HeroSection,
  ServicesWrapper,
  SidePanelWrapper,
  TabItem,
  SidePanelPlatformsWrapper,
  SidePanelSubscriptions,
  FooterWrapper,
  Loader,
} from "../../components";
import { Container } from "react-bootstrap";
import "../../styles/screens/myaccount.scss";
import axios from "axios";
import {
  MyAccountText,
  ButtonText,
  lessCharactersErrorMessage,
  nonAlphabticalCharctersErrorMessage,
  DisplayNameSuccessMessage,
} from "../../utils/consts";
import { Accordion } from "react-bootstrap";
import { filterPlatformsByVisible, getFilteredServices } from "../../utils/helpers";
import { useGetMySubscriptions } from "../../queries/useGetMySubscriptions";
import { useOktaAuth } from "@okta/okta-react";
import toast, { Toaster } from "react-hot-toast";

interface User {
  displayName: string;
  lastName: string;
  email: string;
  name: string;
}

const prepareUserInfo: any = (userInfo: any) => {
  return {
    locale: null,
    email: userInfo.email,
    profileUrl: null,
    lastName: userInfo.family_name,
    preferredLanguage: null,
    Forevermark_FMJ_UserMarketId: null,
    Forevermark_USAMarketing_UserLocation: null,
    displayName: userInfo.given_name,
    timezone: null,
    login: userInfo.email,
    title: null,
    employeeNumber: null,
    division: null,
    Forevermark_SproutLoad_CountryCode: null,
    honorificSuffix: null,
    Forevermark_FMJ_UserRole: null,
    department: null,
    Forevermark_SproutLoad_PostalAddress2: null,
    manager: null,
    nickName: null,
    costCenter: null,
    secondEmail: "",
    honorificPrefix: null,
    managerId: null,
    Forevermark_FMJ_CountryRestriction: null,
    Forevermark_SproutLoad_AccountId: null,
    firstName: userInfo.given_name,
    Forevermark_SproutLoad_CurrencyCode: null,
    mobilePhone: null,
    organization: null,
    Forevermark_USAMarketing_Readonly: null,
    middleName: null,
    userType: null,
    Forevermark_FMJ_TradingEntityIds: null,
    Forevermark_SproutLoad_SalesforceId: null,
  };
};

const updateLocalStorage = (displayName: string) => {
  const currentValue = window.localStorage.getItem("okta-token-storage");

  if (!currentValue) {
    return null;
  }

  const clonedData = JSON.parse(currentValue);
  const updatedData = {
    ...clonedData,
    idToken: {
      ...clonedData.idToken,
      claims: {
        ...clonedData.idToken.claims,
        name: displayName,
      },
    },
  };

  window.localStorage.setItem("okta-token-storage", JSON.stringify(updatedData));
};

export const MyAccount = () => {
  const auth = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [size, setSize] = useState(1280);
  const [isHeaderDark, setIsHeaderDark] = useState(false);
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [username, setUsername] = useState<any>("");
  const [refresh, setRefresh] = useState(false);
  const { data: mySubscriptions } = useGetMySubscriptions();
  const { isLoading, isError, data: platforms } = useGetPlatforms();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          const mappedUserInfo = prepareUserInfo(info);
          setUserInfo((prevState) => ({ ...prevState, ...mappedUserInfo }));
          setUsername(info?.name);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsHeaderDark(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const calculateSize = () => {
      setSize(window.innerWidth);
    };
    calculateSize();
    window.addEventListener("resize", calculateSize);
    return () => {
      window.removeEventListener("resize", calculateSize);
    };
  }, []);

  if (isLoading || isError || !Array.isArray(platforms)) {
    return <Loader hasError={isError} />;
  }
  const handleClickOpenSidePanel = () => {
    setShowSidePanel(!showSidePanel);
  };

  const visiblePlatforms = !authState?.isAuthenticated ? filterPlatformsByVisible(platforms) : platforms;

  const nonSubscribedServices = getFilteredServices(visiblePlatforms, mySubscriptions!);

  const tabSwitcherData: TabItem[] = [
    {
      title: "My subscriptions",
      content: <SidePanelSubscriptions />,
      hide: !authState!.accessToken?.accessToken,
    },
    {
      title: "All Services",
      content: <SidePanelPlatformsWrapper onClose={() => setShowSidePanel(false)} data={nonSubscribedServices} />,
    },
  ];

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof event.target.value === "string") {
      setUsername(event.target.value);
    }
  };
  const validateData = () => {
    if (typeof username !== "string") {
      toast.error(nonAlphabticalCharctersErrorMessage);
      return false;
    }

    if (username.trim().length <= 3) {
      toast.error(lessCharactersErrorMessage);
      return false;
    } else if (username.trim().match(/[^a-zA-Z]+$/)) {
      toast.error(nonAlphabticalCharctersErrorMessage);
      return false;
    }
    return true;
  };

  const updateDataHandler = async () => {
    const isDataValid = validateData();

    if (!isDataValid) {
      return false;
    }

    try {
      console.log(prepareUserInfo);
      const displayName = username.trim();
      const updatedDisplayName: any = {
        profile: {
          ...userInfo,
          displayName,
        },
      };

      const headers = {
        headers: {
          Accept: "application/json; okta-version=1.0.0",
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("okta-token-storage") || "{}")?.accessToken?.accessToken,
        },
      };

      await axios
        .put("https://okta-preview.debeersgroup.com/idp/myaccount/profile", updatedDisplayName, headers)
        .then((res) => console.log(res))
        .catch((error) => {
          console.error("There was an error!", error);
        });

      updateLocalStorage(displayName);

      toast.success(DisplayNameSuccessMessage);

      setRefresh((prevValue) => !prevValue);
    } catch (e) {
      // console.log("something went wrong with revocation token", e);
    }
  };

  return (
    <>
      <Container fluid className={`header-container dark`}>
        <Header handleClickOpenSidePanel={handleClickOpenSidePanel} />
      </Container>
      {authState?.isAuthenticated && (
        <>
          <div className="card border-0 myprofile">
            <div className="row mb-6 myprofile-heading">
              <div className="d-flex justify-content-between">{MyAccountText.MyAccountTitle}</div>
              <div></div>
            </div>
            <Accordion className="accordion-list accItems" defaultActiveKey="1" flush>
              <Accordion.Item key={1} eventKey="1">
                <Accordion.Header className="accItems-header">Personal Details</Accordion.Header>
                <Accordion.Body>
                  <div className="card-body myprofile-container">
                    <div>
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <h5 className="mb-2 user-parameter">{MyAccountText.FirstNameTitle}</h5>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-6 text-secondary">
                          <input
                            type="text"
                            className="form-control input-noneditable"
                            value={userInfo?.displayName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <h5 className="mb-2 user-parameter">{MyAccountText.SurNameTitle}</h5>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-6 text-secondary">
                          <input
                            type="text"
                            className="form-control input-noneditable"
                            value={userInfo?.lastName}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <h5 className="mb-2 user-parameter">{MyAccountText.EmailIdTitle}</h5>
                        </div>
                        <div className="col-sm-6 col-sm-6 col-md-7 col-lg-6">
                          <input
                            type="text"
                            className="form-control input-noneditable"
                            value={userInfo?.email}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-sm-4">
                          <h5 className="mb-2 user-parameter">{MyAccountText.DisplayNameTitle}*</h5>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-6 text-secondary">
                          <input
                            type="text"
                            className="form-control"
                            value={username}
                            placeholder={MyAccountText.DisplayNamePlaceholder}
                            onChange={handleNameChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-md-around align-items-center action-buttons">
                      <div className="col-0 col-sm-1 col-md-1 col-lg-4"></div>
                      <div className="col-5 col-sm-2 col-md-4 col-lg-2">
                        <input
                          type="button"
                          className="launch-button btn-primary-blue"
                          value={ButtonText.Update}
                          onClick={updateDataHandler}
                        />
                      </div>
                      <div className="col-1 col-sm-1 col-md-1 col-lg-0"></div>
                      <div className="col-4 col-sm-2 col-md-4 col-lg-2" onClick={() => navigate("/")}>
                        <input type="button" className="launch-button btn-primary-blue" value={ButtonText.Cancel} />
                      </div>
                      <div className="col-sm-3 col-md-2 col-lg-3"></div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item key={2} eventKey="2">
                <Accordion.Header>Change Password</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      type="button"
                      className="changePwd-button btn-small btn-primary-blue text-right noneditable-btn"
                      value="Change Password"
                      disabled
                    >
                      Change Password
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </>
      )}
      {showSidePanel && (
        <SidePanelWrapper
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          tabSwitcherData={tabSwitcherData}
          selectedTab={!authState!.accessToken?.accessToken ? selectedTab + 1 : selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
      {authState?.isAuthenticated && <FooterWrapper isMyAccount={true} />}
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};
