import { SidePanelPlatform } from "..";
import { Platform } from "../../utils/models";
import { useNavigate } from "react-router-dom";
import { Stream } from "../../utils/consts";

type SidePanelPlatformsWrapperProps = {
  data: Platform[];
  onClose: () => void;
};

export const SidePanelPlatformsWrapper = ({ data, onClose }: SidePanelPlatformsWrapperProps) => {
  const navigate = useNavigate();
  const handleOnCLickPlatform = (appName: string) => {
    navigate("/" + appName);
    onClose();
  };

  const blockLink = () => {
    navigate("");
  };
  return (
    <>
      {data.map(
        (platforms, index) =>
          platforms.name !== Stream && (
            <SidePanelPlatform
              onClick={platforms.name === Stream ? blockLink : handleOnCLickPlatform}
              key={index}
              image={platforms.icon}
              name={platforms.name}
              id={platforms.id}
              appName={platforms.appName}
            />
          ),
      )}
    </>
  );
};
