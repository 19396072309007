import { Footer } from "..";

export const FooterWrapper = ({
  isTemplate,
  isMyAccount,
  isSignIn,
  isLogin,
}: {
  isTemplate?: boolean;
  isMyAccount?: boolean;
  isSignIn?: boolean;
  isLogin?: boolean;
}) => {
  return (
    <div
      className={
        "footer-wrapper " +
        (isTemplate ? "template-footer" : "") +
        (isMyAccount ? "myaccount-footer" : "") +
        (isSignIn ? "signin-footer" : "") +
        (isLogin ? "login-footer" : "")
      }
    >
      <Footer />
    </div>
  );
};
