import { Col, Row } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import { SwitcherTimeSwitch } from "../../utils/consts";
import { ImageBlock } from "./partial/image";
import { VideoBlock } from "./partial/video";

export type SwitcherProps = {
  images: string[];
  is1024?: boolean;
  useFixed?: boolean;
};

const isImage = (fileName: string) => {
  return (
    fileName.toLowerCase().includes(".jpg") ||
    fileName.toLowerCase().includes(".jpeg") ||
    fileName.toLowerCase().includes(".png")
  );
};
const heightCorrection = 200;
const baseHeight = "70%";
const base1024Height = "600px";

export const Switcher = ({ images, is1024, useFixed }: SwitcherProps) => {
  const [active, setActive] = useState(0);
  const [height, setHeight] = useState<string>(baseHeight);
  const [left, setLeft] = useState<string>(baseHeight);
  const [width, setWidth] = useState<string>(baseHeight);
  const timer = useRef<NodeJS.Timeout | undefined>();
  const ref = useRef<any>(null);

  const nextItem = () => {
    if (active === images.length - 1) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };

  const handleResetTimeout = () => {
    const interval = timer?.current;
    if (interval) {
      clearTimeout(interval);
    }
  };

  const detectHeight = useCallback(() => {
    setTimeout(() => {
      const activeVideo = document.querySelector(".switcher .image.active video");
      if (activeVideo) {
        const rect = activeVideo.getBoundingClientRect();
        const newHeight = rect.height;
        setHeight(newHeight.toString() + "px");
      } else {
        setHeight(is1024 ? base1024Height : baseHeight);
      }

      if (ref && ref.current) {
        const parent = ref.current.parentElement;
        const rect = parent.getBoundingClientRect();
        setLeft(rect.left);
        setWidth(rect.width);
      }
    }, 4000);
  }, [is1024]);

  const handleCreateTimeout = () => {
    handleResetTimeout();

    if (images.length > 1 && isImage(images[active])) {
      timer.current = setInterval(() => {
        nextItem();
      }, SwitcherTimeSwitch);
    }
  };
  useEffect(() => {
    setActive(0);
  }, [images]);

  useEffect(() => {
    detectHeight();
    window.addEventListener("resize", detectHeight);
    return () => {
      window.removeEventListener("resize", detectHeight);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [active]);

  useEffect(() => {
    handleCreateTimeout();

    return handleResetTimeout;
  });

  const handleOnSelect = (index: number) => {
    handleCreateTimeout();
    setActive(index);
  };

  const style: any = {};
  if (!is1024) {
    style.width = "46vw";
    style.left = (+left - 50).toString() + "px";
    style.paddingTop = "1vh";
    if (images[0].includes("origin/gallery/")) {
      const ele = document.getElementById("root");
      let dimension: any = ele?.getBoundingClientRect();
      console.log("Width: " + dimension.width + "px" + "   Height: " + dimension.height + "px");
      style.width = dimension.width / 6;
      style.left = (+left + 100).toString() + "px";
    }
  }

  return (
    <Row ref={ref} className={"flex-column switcher " + (useFixed ? " fixed" : "")} style={style}>
      <Col
        className={"images"}
        style={{
          minHeight: height,
        }}
      >
        {images.map((image, index) => {
          if (isImage(image)) {
            return (
              <ImageBlock
                key={index}
                isActive={index === active}
                onClick={() => {
                  if (images.length > 1) {
                    const num = index + 1 < images.length ? index + 1 : 0;
                    handleOnSelect(num);
                  }
                }}
                image={image}
              />
            );
          }
          return <VideoBlock key={index} path={image} isActive={index === active} />;
        })}
      </Col>
      <Col className={"dots"}>
        <Row className="justify-content-center">
          {images.length > 1 &&
            images.map((image, index) => (
              <div
                key={index}
                className={`dot ${index === active ? "active" : ""}`}
                onClick={() => handleOnSelect(index)}
                onMouseOver={handleResetTimeout}
                onMouseOut={handleCreateTimeout}
              >
                <div />
              </div>
            ))}
        </Row>
      </Col>
    </Row>
  );
};
