import { useEffect, useLayoutEffect, useState } from "react";
import { Index } from "../../../screens/Index";
import { FooterWrapper } from "../../FooterWrapper";
import { useAuth } from "../../../hooks";
import { useOktaAuth } from "@okta/okta-react";

export const Cookies = () => {
  const [htmlCookies, setHTMLCookies] = useState("");
  const auth = useAuth();
  const { authState } = useOktaAuth();
  var policyHeading = "policy-headingC1";
  if (authState !== null) {
    policyHeading = !authState!.accessToken?.accessToken ? "policy-headingC1" : "policy-headingC2";
  }

  useEffect(() => {
    // get information about cookies
    if (htmlCookies === "") {
      const item = document.querySelector("#ot-sdk-cookie-policy");
      setHTMLCookies(item?.innerHTML || "");
    }
  }, [htmlCookies]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (authState !== null && !authState.accessToken?.accessToken) {
    setTimeout(() => {
      const item = document.querySelector("#ot-sdk-cookie-policy");
      setHTMLCookies(item?.innerHTML || "");
    }, 2000);
  }
  return (
    <>
      <Index isLandingPage={false} />
      <div className="container text-container">
        <h3 className={`light center ${policyHeading}`}>Our Use of Cookies</h3>
        <p>COOKIE POLICY</p>
        <p>
          We may collect information about your computer, including where available your IP address, operating system
          and browser type, for system administration and to report aggregate information to Our advertisers. This is
          statistical data about Our users’ browsing actions and patterns and does not show personal details that
          identify you.
        </p>
        <p>
          For the same reason, we may obtain information about your general internet usage by using a cookie file which
          is stored on the hard drive of your computer. Cookies are small pieces of information sent by a web server to
          a web browser which allow the web server to recognise the web browser. They help Us to improve Our Site and to
          deliver a better and more personalised service. They enable Us:
        </p>
        <p>To estimate Our audience size and usage pattern.</p>
        <p>
          To store information about your preferences, and so allow Us to customise Our Site according to your
          individual interests.
        </p>
        <p>To speed up your searches.</p>
        <p>To recognise you when you return to Our Site.</p>
        <p>
          You can control how your browser handles cookies received from websites. You can choose to refuse all cookies,
          or to be prompted before a cookie is saved to your hard drive, or to only accept cookies from certain websites
          that you designate. Information on deleting or controlling cookies is available at www.AboutCookies.org. By
          refusing to accept cookies from Us, you may not be able to use some of the features and functionality
          available on Our Site. Our server automatically collects data about your server’s internet address when you
          visit Us. This information, known as an Internet Protocol address, or IP Address, is a number that’s
          automatically assigned to your computer by your internet service provider whenever you’re on the internet.
          When you request pages from Our Site, Our servers may log your IP Address and sometimes your domain name. Our
          server may also record the referring page that linked you to Us (e.g., another website or a search engine);
          the pages you visit on Our Site; the website you visit after Our Site; the ads you see and/or click on; other
          information about the type of web browser, computer, platform, related software and settings you are using;
          any search terms you have entered on Our Site or a referral site; and other web usage activity and data logged
          by Our web servers. We use this information for internal system administration, to help diagnose problems with
          Our server, and to administer Our Site. Such information may also be used to gather broad demographic
          information, such as country of origin and internet service provider. Any or all of activities with regard to
          Our Site usage information may be performed on our behalf by our services providers.
        </p>
        <p></p>
        <div dangerouslySetInnerHTML={{ __html: htmlCookies }} />
      </div>
      <FooterWrapper />
    </>
  );
};
