import { FooterWrapper, IconConfirm, IconRight, Loader } from "..";
import image from "../../assets/images/landing-page-static-image.png";
import { ButtonText, ExploreProducts, HeroSectionText } from "../../utils/consts";
import animationData from "../../assets/lottiefile/Explore-old.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useGetPlatforms } from "../../queries/useGetApps";
import { useAuth } from "../../hooks";
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { Login } from "../../screens/Login";
import { render } from "react-dom";
import { decodeToken } from "@okta/okta-auth-js";
// import { useAuthOkta } from "../../hooks";

export const HeroSection = ({ handleClickOpenSidePanel }: { handleClickOpenSidePanel: () => void }) => {
  const { isLoading, data: platforms } = useGetPlatforms();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  // const authOkta = useAuthOkta();

  useEffect(() => {
    setIsLoaded((isLoaded) => true);
  }, []);

  const scrollToExplore = () => {
    const item = document.querySelector(".services-wrapper h3");
    const rect = item?.getBoundingClientRect();
    if (rect) {
      window.scrollTo(0, rect.y - 150);
    }
  };
  const auth = useAuth();
  const center = authState !== null ? (!authState.accessToken?.accessToken ? "center" : "") : "center";

  if (isLoading || !Array.isArray(platforms) || !isLoaded) {
    return <Loader />;
  }
  const handleLogin = () => {
    if (!authState?.isAuthenticated) {
      window.sessionStorage.clear();
      navigate("/login");
      window.location.reload();
    }
  };
  return (
    <div className={`hero-section ${center}`}>
      <img
        className="background-video"
        src={image}
        alt="Table top shot of rough and polished diamonds next to each other"
      />
      <div className="hero-background-img"></div>
      <div className={`hero-section-text ${center}`}>
        <div className="headline">{HeroSectionText.Headline}</div>
        <h5 className="light">{HeroSectionText.Slogan}</h5>
        {!authState?.isAuthenticated && (
          <div className="sign-in">
            <button className="btn-primary-blue" onClick={handleLogin}>
              {ButtonText.LandingPageSignIn} <IconRight />
            </button>
          </div>
        )}
        {authState?.isAuthenticated && (
          <div className="info-button-wrapper">
            <button className="btn-primary-blue" onClick={handleClickOpenSidePanel}>
              {ButtonText.Explore} <IconRight />
            </button>
            <div className="info">
              <IconConfirm /> {HeroSectionText.Products.replace("{number}", platforms?.length.toString())}
            </div>
          </div>
        )}
      </div>
      {authState?.isAuthenticated && (
        <div className="block-more">
          <div className="explore-products" onClick={scrollToExplore}>
            <div className="circle">
              <Player autoplay loop src={animationData} style={{ height: "56px", width: "56px" }}></Player>
            </div>
            <div className="caption-large-upper medium">{ExploreProducts}</div>
          </div>
        </div>
      )}
      {!authState?.isAuthenticated && <FooterWrapper isSignIn={true} />}
    </div>
  );
};
