import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "react-bootstrap";
import moment from "moment-timezone";
import ReactGA from "react-ga4";

//Initialize GA4
ReactGA.initialize("G-2CZGB6FDPQ");

moment.tz.setDefault("UTC");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider breakpoints={["xxxl", "xxl", "xl", "ls", "lg", "md", "sm", "xs"]} minBreakpoint="xs">
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
