import { Index } from "../../../screens/Index";
import { FooterWrapper } from "../../FooterWrapper";
import { useAuth } from "../../../hooks/Auth";
import { useOktaAuth } from "@okta/okta-react";

export const TnC = () => {
  const auth = useAuth();
  const { authState } = useOktaAuth();
  var policyHeading = "policy-headingTnC1";
  if (authState !== null) {
    policyHeading = !authState.accessToken?.accessToken ? "policy-headingTnC1" : "policy-headingTnC2";
  }
  return (
    <>
      <Index isLandingPage={false} />
      <div className="container text-container">
        <h3 className={`light center ${policyHeading}`}>Terms & Conditions</h3>
        <p>
          TERMS AND CONDITIONS APPLICABLE TO THE USE OF:{" "}
          <a href="https://explore.debeersgroup.com" target="_blank" rel="noreferrer">
            https://explore.debeersgroup.com
          </a>
        </p>
        <p>(THE "TERMS AND CONDITIONS")</p>
        <p>
          In these Terms and Conditions:"De Beers Group of Companies" means any company directly or indirectly
          controlled by De Beers plc of 44 Esplanade, St Helier, Jersey, JE4 9WG."De Beers UK Limited", “DBUK”,
          "company" "we", "us" and/or "our" company is incorporated and registered in England and Wales under company
          number 02054170 and whose registered office is at 17 Charterhouse Street, London, EC1N 6RA, UK.
        </p>
        <p>"User", "you" and/or "your" means any person who uses our Site.</p>
        <p>1. Introduction</p>
        <p>
          1.1 Please read these Terms and Conditions carefully and make sure that you understand them before you start
          to use the website operated by "De Beers UK Limited" at{" "}
          <a href="https://explore.debeersgroup.com" target="_blank" rel="noreferrer">
            https://explore.debeersgroup.com
          </a>{" "}
          which shall include all sub-domains and secure access area (the "Site"). We recommend that you print a copy of
          these Terms and Conditions for future reference.1.2 These Terms and Conditions, together with the documents
          referred to within them, govern your access to, and use of, the Site and the content on it. 1.3 We reserve the
          right to amend these Terms and Conditions from time to time without notice and at our discretion. It is your
          responsibility to review this page for updates to these Terms and Conditions, which shall come into effect and
          be binding once posted. 1.4 You agree that your continued use of the Site (including accessing, browsing or
          registering to use the Site) will be deemed acceptance by you to be bound by these Terms and Conditions and is
          subject to your continued compliance with these Terms and Conditions. IF YOU DO NOT ACCEPT THESE TERMS AND
          CONDITIONS PLEASE LEAVE THE SITE NOW.1.5 The Site comprises of a public access area and a secure access
          area:1.5.1 the public access area contains general information about "De Beers UK Limited", provided solely
          for general informational purposes and a contact form.1.5.2 the secure access area is a password protected
          section of the Site solely for "De Beers UK Limited" business clients ("Clients"). There are separate terms
          and conditions governing the provision of services to our clients – Clients must agree to those terms and
          conditions before being able to submit requests for services.1.6 These Terms and Conditions refer to our
          Acceptable Use Policy, which sets out the permitted and prohibited uses of the Site. When using the Site, you
          must comply with this policy.
        </p>
        <p>2. Intellectual Property</p>
        <p>
          2.1 We are the owner and/or authorised licence holder of all material, content, video, trademarks, design
          rights, patents, copyrights, database rights and all other intellectual property (including, but not limited
          to, text, images, web pages, sound, software (including, code, interface and website structure and the look
          and feel, design and compilation of it) appearing on or contained within the Site, unless otherwise expressly
          indicated.2.2 You agree that you are permitted to use this material and/or content solely as set out in these
          Terms and Conditions or as otherwise expressly authorised in writing by us or our licensors. Except as
          provided in these Terms and Conditions, use of or access to the Site does not grant you any right, title,
          interest or licence to any such intellectual property you may access on the Site (including for purposes of
          clarity, any intellectual property in User Information (defined below) uploaded onto the Site).2.3 Nothing
          contained on this Site should be construed as granting, by implication, estoppel, or otherwise, any licence or
          right to use any trade mark, service mark or logo displayed on the Site without our prior written permission
          or the prior written permission of such other third party that may own the trade mark, service mark or logo
          displayed on the Site. Your use of the trademarks, service marks or logos displayed on this Site, except as
          provided herein, is strictly prohibited.
        </p>
        <p>3. Copying</p>
        <p>
          3.1 You may view the public access section of the Site and you are welcome to print hard copies of material on
          it solely for your lawful, personal, non-commercial use. Reproduction by the user of the whole of the Site is
          strictly prohibited.3.2 Clients are, however, subject to the remaining provisions of these Terms and
          Conditions, permitted to download information from the Site (unless expressly stated otherwise) as follows:(i)
          information from the Site shall not be modified in any way;(ii) any illustrations, photographs, video or audio
          sequences or any graphics must not be used separately from any accompanying text;(iii) information from the
          Site shall not be used in breach of any applicable laws, including the Data Protection Act 2018 (or any
          amendments or updates thereto);(iv) the contents of the Site may be saved to disk or otherwise stored in a
          private electronic retrieval system or may be printed provided that: (a) such use is for the user’s own
          personal, non-commercial use only; (b) such contents are deleted from any disk or private electronic retrieval
          system after 30 days from when such content was saved or stored; and (c) no attempt is made by you or anyone
          acting on your behalf to replicate, resell, hire or to otherwise commercially benefit from any information
          contained in the Site; and (v) the copyright notice ‘© "De Beers UK Limited 2023” ’ must appear in all
          reproductions and/or copies.3.3 All other copying, whether in electronic, hard copy or other format, is
          prohibited and may breach intellectual property laws and other laws worldwide. You agree that you are
          permitted to use this material and/or content only as set out in these Terms and Conditions or as otherwise
          expressly authorised in writing by us or our licensors, and that you are not otherwise entitled to copy,
          reproduce, transmit, publicly perform, distribute, commercially exploit, adapt, translate, modify, bundle,
          merge, share or make available to any person, or create derivative works of such material or content, or use
          it for commercial purposes, without our prior written consent. All other rights are reserved.3.4 You may not
          offer for sale or distribute over any medium, any part of this Site or any of its content.3.5 In accordance
          with Condition 13, we reserve the right to limit or refuse access to the Site or any portion of the Site to
          anyone who fails to comply with or is suspected to not be in compliance with the provisions of this Condition
          3.
        </p>
        <p>4. Secure Access Area of the Site</p>
        <p>
          4.1 The secure access area of the Site shall be accessed and used by Clients and their representatives only.
          4.2 Before a client or their representatives are given access to the secure access area of the Site, the
          Client will be vetted by us and will be required to undergo thorough KYC checks. If the client passes such
          checks, we will send the Client (and if applicable its nominated representatives) an email invitation to the
          secure access area of the Site requesting the Client (and if applicable its representatives) to register on
          the secure access area of the Site, set a unique password and formally accept these Terms and Conditions. 4.3
          To gain access to the secure access area of the Site you will be required to log-in using your registered
          email address and unique password.4.4 If we deem it necessary for security reasons, we can enforce a password
          change at any time, but we will warn you in advance should we intend to do so.4.5 Your password should be
          treated as confidential for the duration of time that you are entitled to access and use the secure access
          area of the Site and any services available on it. Your password should be kept safe, and you should not
          disclose it to anyone. Usage of an individual registered email address by multiple users or by someone other
          than the authoriseduser is a serious breach of these Terms and Conditions and could lead to De Beers UK
          Limited suspending or removing your access to the secure access area of the Site for a period of time or
          indefinitely at De Beers UK Limited’s discretion depending on the severity of the breach. You shall inform De
          Beers UK Limited immediately of any known or perceived breach of this provision by notifying us at{" "}
          <a href="mailto:contactexplore@debeersgroup.com">contactexplore@debeersgroup.com</a> and you shall be liable
          for any loss or damage that may result from having disclosed or failing to keep secure your sign-in details
          and/or password.
        </p>
        <p>5. Copyright</p>
        <p>
          5.1 All rights, including copyright and database rights, in this Site are and shall remain vested in De Beers
          UK Limited. All software belongs to De Beers UK Limited or our third-party licensors. All rights are reserved.
        </p>
        <p>6. No Warranties</p>
        <p>
          6.1 The Site (including any information, materials, text, graphics, links, or other items included in this
          Site), is provided “as is”, “with all faults” and “as available”, without warranties, express or implied, of
          any kind, and your use of it is at your own risk.6.2 Please note that we are under no obligation to update the
          content and it may be out of date at any given time. We do not guarantee that the Site or any content on it
          will be free from errors or omissions.6.3 We do not warrant that the Site will be free from viruses, available
          at any time or that the contents on the Site will be accurate. Although we take reasonable steps to secure the
          Site, you acknowledge that the internet is not a completely secure medium and we make no warranties, express
          or implied, that any information or materials you post on or transmit through the Site will be safe from
          unauthorized access or use.6.4 The material displayed and information provided on the public access area of
          the Site is for general information purposes only and whilst De Beers UK Limited endeavours to ensure that the
          information is correct as at the date of publication, no warranties, express or implied, guarantees or
          conditions are given as to its accuracy or completeness.6.5 The material displayed and information provided on
          the Site is provided for general information only. It is not intended to amount to advice on which you should
          rely. You must obtain professional or specialist advice and/or verify the information set out on the Site with
          independent information sources and/or authorities before taking, or refraining from taking, any action based
          on any material or information on the Site. 6.6 If you are dissatisfied with the Site, your sole remedy is to
          discontinue using the Site.
        </p>
        <p>7. Third Party Sites</p>
        <p>
          7.1 The Site may contain links and/or advertisements to other websites maintained by us in addition to links
          to websites maintained by unrelated companies and persons. An advertisement of, or link to, a non-De Beers
          Group of Companies website does not mean that De Beers UK Limited approves, endorses or accepts any
          responsibility for that website, its content or use, or the products and services made available through such
          website.7.2 Any arrangement made by you with a third party as a result of you accessing another website via a
          link from the Site shall be an arrangement between you and the relevant third party and is at your sole risk
          and responsibility.7.3 We are not responsible for the actions, content, accuracy, opinions expressed, privacy
          policies, products or services provided through these links or made available through these resources or
          appearing in such websites, nor for any damages or losses, directly or indirectly, caused or alleged to have
          been caused as a result of your use or reliance on such websites.7.4 Such websites are not investigated,
          monitored or checked for accuracy, completeness or conformance with applicable laws and regulations by us. We
          do not make any representations whatsoever, or give any warranties of any kind, expressed, implied, or
          otherwise about other websites which you may access through the Site, the content thereof, or the products
          and/or services made available through such websites. If you decide to leave the Site and access these other
          websites, you do so at your own risk. All rules, policies (including privacy policies) and operating
          procedures of such websites will apply to you while on such websites.7.5 We will not be liable for any damages
          or losses or expenses, even if foreseeable, that may arise from your use of these third-party websites or for
          the actions and customer information practices of any website to which the Site links or refers.
        </p>
        <p>8. Your Responsibility</p>
        <p>
          8.1 It is your responsibility to use virus checking software on any material downloaded from the Site and to
          ensure the compatibility of such software with your equipment.8.2 In circumstances where you provide us with
          information relating to any third parties, you confirm that you have received that third party’s prior consent
          in relation to such disclosure and that the third party has been informed of our Privacy and Cookies Policy
          and the uses which we may make of such information. You should also check that the information you provide
          does not contain any content prohibited by applicable laws or regulations. You will be responsible for any
          loss or damages we may suffer if you breach this clause.
        </p>
        <p>9. Information Submitted by You</p>
        <p>
          9.1 Where you make use of a feature which allows you to upload content to us or the Site, or to make contact
          with other users of the Site, you must comply with the standards set out in our Acceptable Use Policy.9.2 You
          confirm that any such contribution does comply with those standards, and:9.2.1 if you are a consumer you will
          be responsible and liable to us in respect of; or 9.2.2 if you are not a consumer you will indemnify us in
          respect of,any breach of this clause. 9.3 We have the right to remove any content or posting you make on the
          Site if, in our opinion, such material does not comply with the content standards set out in our Acceptable
          Use Policy.9.4 All content, whether publicly posted or privately transmitted, is the sole responsibility of
          the person who originated such content. We may not monitor or control the content posted to the Site by users
          and, we cannot take responsibility for such content. Any use or reliance on any third-party content or
          materials posted via the Site or obtained by you through the Site is at your own risk.9.5 We do not endorse,
          support, represent or guarantee the completeness, truthfulness, accuracy, or reliability of any content or
          other material posted on the Site or endorse any opinions expressed on the Site. You understand that by
          accessing the Site, you may be exposed to content that might be offensive, harmful, inaccurate or otherwise
          inappropriate, or in some cases, postings that have been mislabelled or are otherwise deceptive. Under no
          circumstances will we be responsible, or liable to you or any third party, for the content or accuracy of any
          materials posted by you or any other user of the Site, or any loss or damage of any kind incurred as a result
          of the use of any content or other materials posted, emailed, transmitted or otherwise made available on the
          Site.
        </p>
        <p>10. Liability</p>
        <p>
          Nothing in these Terms and Conditions excludes or limits our liability for death or personal injury arising
          from our negligence, or our fraud or fraudulent misrepresentation, or any other liability which cannot be
          excluded under English law or other applicable law. Subject to the foregoing, if you are a consumer user and
          we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable
          result of our breach of these Terms. Loss or damage is foreseeable if it was an obvious consequence of our
          breach or if it was contemplated by you and us at the time that you accessed the Site. Notwithstanding the
          paragraph above, if you are a consumer, we will not be liable for any loss, cost or damage whether in
          contract, tort (including negligence), breach of statutory duty, or otherwise even if foreseeable, suffered by
          you or any third party as a result of your use of the Site for or in connection with:
        </p>
        <p>• inability to use the Site.</p>
        <p>• any third-party software you may download from the Site; or</p>
        <p>• third party content displayed on the Site.</p>
        <p>
          If you are not a consumer user, please note that we will not be liable for any loss, cost or damage whether in
          contract, tort (including negligence), breach of statutory duty, or otherwise even if foreseeable, suffered by
          you or any third party as a result of your use of the Site for or in connection with:
        </p>
        <p>• use of, or inability to use, the Site.</p>
        <p>• any third-party software you may download from the Site.</p>
        <p>• use of or reliance on any content displayed on the Site.</p>
        <p>• loss of profits, sales, business, or revenue.</p>
        <p>• business interruption.</p>
        <p>• loss of anticipated savings.</p>
        <p>• loss of business opportunity, goodwill or reputation; or</p>
        <p>• any indirect or consequential loss or damage.</p>
        <p>10.1 If you are a consumer user, please note that:10.1.1</p>
        <p>
          We only provide our Site for domestic and private use. You agree not to use our Site for any commercial or
          business purposes, and we have no liability to you for any loss of profit, loss of business, business
          interruption, or loss of business opportunity; and10.1.2 nothing in these Terms affects your statutory rights.
          Advice about your statutory rights is available from your local Citizens’ Advice Bureau or Trading Standards
          Office.10.2 No representation or warranty (express or implied) is given as to the accuracy, reasonableness,
          reliability or completeness of any information provided in connection with the matters contained or referred
          to in the Site.10.3 We will not be liable for any loss or damage caused by a virus, distributed
          denial-of-service attack, or other technologically harmful material that may infect your computer equipment,
          computer programs, data or other proprietary material due to your use of the Site or to your downloading of
          any content on it, or on any website linked to it.10.4 We are not responsible for problems with hosting
          services, servers or internet service providers, telephone networks or telephone services or for any injury or
          damage to a user’s or to any other person’s computer, mobile phone, or other hardware or software, related to
          or resulting from using or downloading materials in connection with the Site or related to or resulting from
          generally using or downloading materials in connection with the internet.10.5 We reserve the right to
          exclusively defend and control any claims arising from any breach of warranties you have made for which you
          agree to indemnify us (or, if you are a consumer, you have agreed that you will be responsible for our loss or
          damage) and you agree that you will fully cooperate with us when we defend those claims.10.6 If you enter into
          any third party transaction as a result of any information sourced from this Site, De Beers UK Limited shall
          not be liable for any use made of that information or any resultant transactions, nor shall De Beers UK
          Limited be responsible for or involved in that transaction in any way.10.7 If you are not a consumer:10.7.1
          subject to Condition 9.1, in no event shall our total liability to you for all damages, costs, losses and
          causes of action in the aggregate (whether in contract, tort, including but not limited to negligence, or
          otherwise) arising from the Terms and Conditions, including our Acceptable Use Policy, or use of the Site
          exceed, in the aggregate, £1,000 (sterling);10.7.2 you shall make clear to all parties with whom you deal
          (including, in particular, to your partners, consumers and agents) that neither De Beers UK Limited nor any
          member of the De Beers Group of Companies is responsible for any of your marketing or business initiatives or
          other activities and neither De Beers UK Limited nor any member of the De Beers Group of Companies is acting
          as agent for or on behalf of you or any member of your group. Neither De Beers UK Limited nor any member of
          the De Beers Group of Companies shall have any liability to any party in connection with any services provided
          to you by us. If requested by us, you shall ensure that such parties provide a written acknowledgement (in a
          form acceptable to us) to that effect directly to De Beers UK Limited; and10.7.3 you shall indemnify, and keep
          indemnified, De Beers UK Limited and all members of the De Beers Group of Companies against any loss, damage
          or cost incurred by De Beers UK Limited or any member of the De Beers Group of Companies arising out of any
          breach by you of these Terms and Conditions, including (without limitation) your use of the Site or any
          information accessible over or through the Site including information obtained from linked sites, or your
          submission or transmission of information or material on or through the Site in breach by you of these Terms
          and Conditions and/or any breach by you of any laws, regulations and rules, save where directly attributable
          to the fraud or wilful default of De Beers UK Limited or relevant member of the De Beers Group of Companies.
          You shall also indemnify De Beers UK Limited and the De Beers Group of Companies against any claims that
          information or material which you have submitted to us is in violation of any law or in breach of any third
          party rights (including, but not limited to, claims in respect of defamation, invasion of privacy, breach of
          confidence, infringement of copyright or infringement of any other intellectual property right). We reserve
          the right to exclusively defend and control any claims arising from the above and any such indemnification
          matters and that you will fully cooperate with us in any such defences.
        </p>
        <p>11. User Information</p>
        <p>
          11.1 In the course of your use of the Site, you may be asked to provide personal information to us such as
          your first and last name, phone number or email address or other personal data within the meaning of
          applicable data privacy law (such information referred to hereinafter as “User Information”). Our information
          collection and use policies with respect to such User Information is set out in our Privacy and Cookies
          Policy. You acknowledge and agree that you are solely responsible for the accuracy and content of the User
          Information.
        </p>
        <p>12. Viruses, Hacking and Other Offences</p>
        <p>
          12.1 You must not misuse the Site by knowingly introducing viruses, trojans, worms, logic bombs or other
          material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to
          the Site, the server on which the Site is stored, or any server, computer or database connected to the Site.
          You must not attack the Site via a denial-of-service attack or a distributed denial-of service attack.12.2 You
          shall not interfere or attempt to interfere with the operation or use of the Site in any way through any means
          or device including, but not limited to, spamming, hacking, uploading computer viruses or time bombs, or any
          other means expressly prohibited by any provision of these Terms and Conditions.
        </p>
        <p>13. Linking to the Site</p>
        <p>
          13.1 You may not frame or deep link this Site. You must not establish a link to suggest any form of
          association, approval or endorsement on our part where none exists.13.2 The Site must not be framed or
          embedded on any other site, nor may you create a link to any part of the Site.
        </p>
        <p>14. Restriction, Suspension and Termination</p>
        <p>
          14.1 Access to the Site is permitted on a temporary basis, and we reserve the right to suspend, withdraw or
          amend the Site without notice.14.2 We may restrict, suspend or terminate your access to the Site, in whole or
          in part (including the right to disable your registered email address or password) and/or your ability to
          avail of any of the services on the Site, including interactive services, with or without notice, for any
          reason at all, including but not limited to if we believe that you have breached these Terms and Conditions.
          Any such restriction, suspension or termination will be without prejudice to any rights which we may have
          against you in respect of any breach of these Terms and Conditions by you.14.3 We may also change, modify,
          substitute or remove without notice any information on the Site from time to time, remove the Site as a whole
          or any sections or features of the Site at any time.14.4 Please note that we have the ability to trace your IP
          address and if necessary contact your ISP in the event of a suspected breach of these Terms and Conditions. We
          will determine your compliance with these Terms and Conditions in our sole discretion and our decision shall
          be final and binding and, unless you are a consumer, not subject to challenge or appeal.
        </p>
        <p>15. Miscellaneous</p>
        <p>
          15.1 These Terms and Conditions and our Acceptable Use Policy, constitute the entire agreement between you and
          us in relation to its subject matter and supersedes any and all prior promises, representations, agreements,
          statements and understandings (whether written or oral) whatsoever between us.15.2 The parties agree and
          acknowledge that they do not rely on, and shall have no remedy in respect of, any statement, warranty or
          understanding of any person other than as expressly set out in these Terms and Conditions.15.3 To the extent
          that software is available through the Site, such software may be subject to a licence agreement that is
          distributed or included with such software and you agree to comply with the terms and conditions of any such
          software licence agreements that we may communicate to you from time to time.15.4 The failure by us to
          exercise or enforce any right or provision of the Terms and Conditions shall not constitute a waiver of such
          right or provision.15.5 The parties intend each provision of these Terms and Conditions to be severable and
          distinct from the others. If any provision of these Terms and Conditions is held by a court of competent
          jurisdiction to be illegal, invalid or unenforceable, in whole or in part, the parties nevertheless agree that
          the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the
          legality, validity and enforceability of the remainder of these Terms and Conditions shall not be
          affected.15.6 All disclaimers, indemnities and exclusions in these Terms and Conditions shall survive
          termination.15.7 You may not assign your rights or obligations under these Terms to anyone. We may without
          your prior permission cede, assign or otherwise transfer our rights and obligations in terms of these Terms
          and Conditions to third parties, provided that if you are a consumer such assignment or transfer by us will
          not affect your rights or obligations under these Terms.15.8 If you wish to have more information on online
          dispute resolution, please follow this link to the website of the European Commission:{" "}
          <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer">
            https://ec.europa.eu/consumers/odr
          </a>{" "}
          . This link is provided as required by Regulation (EU) No 524/2013 of the European Parliament and of the
          Council, for information purposes only. We are not obliged to participate in online dispute resolution.
        </p>
        <p>16. Law and Jurisdiction</p>
        <p>
          16.1 These Terms and Conditions and Acceptable Use Policy, any matter relating to this Site, any
          non-contractual obligations arising out of or in relation to these Terms and Conditions and any dispute or
          claim arising out of or in connection with them or their subject matter or formation (including
          non-contractual disputes or claims) shall be governed by an interpreted in accordance with English law.16.2 If
          you are not a consumer, the English Courts shall have exclusive jurisdiction over any claim arising from, or
          related to, a visit to the Site, although we retain the right to bring proceedings against you for breach of
          these Terms and Conditions in your country of residence or any other relevant country.16.3 If you are a
          consumer: 16.3.1 you can bring proceedings in respect of these Terms and Conditions in: (i) the English
          courts; (ii) if you live in Scotland, either the Scottish or the English courts; (iii) if you live in Northern
          Ireland, either the Northern Irish or the English courts; or (iv) if you live in a European Union member
          state, the courts of the European Union member state in which you are resident or the English courts;
          and16.3.2 and you are resident in the European Union and we direct this Site to the member state in which you
          are resident, you will benefit from any mandatory provisions of the law of the country in which you are
          resident. Nothing in these Terms and Conditions, including Condition 16.1, affects your rights as a consumer
          to rely on such mandatory provisions of local law.
        </p>
        <p>17. Translation (Prevail Condition)</p>
        <p>
          17.1 In the event there is a conflict between this Global English version of the Terms and Conditions and a
          translated version, this Global English version shall prevail.
        </p>
        <p>
          18. Contact InformationDe Beers UK Limited (company number: 02054170), 17 Charterhouse Street, London, EC1N
          6RA, UK.Telephone: +44 (0) 20 7858 7887
        </p>
        <p>
          Email: <a href="mailto:contactexplore@debeersgroup.com">contactexplore@debeersgroup.com</a> For grievance and
          compliance related questions please contact:{" "}
          <a href="mailto:exploresupport@debeersgroup.com">exploresupport@debeersgroup.com</a>
        </p>
        <p>Thank you for visiting the Site.</p>
        <p>Terms and Conditions last updated 1 April 2023© DBUK Limited 2023</p>
      </div>
      <FooterWrapper />
    </>
  );
};
