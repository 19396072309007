import { Col, Row } from "react-bootstrap";
import { ButtonText, ServicesWithoutExpandibleSections } from "../../../utils/consts";
import { AccordionList, IconRight, Switcher } from "../../../components";
import { AccordionItem, Platform } from "../../../utils/models";

export const SizeNormal = ({ page }: { page: Platform }) => {
  return (
    <Row className={"template services-wrapper"}>
      <Col>
        <Row>
          <Col>
            <h1>{page?.name}</h1>
            <p className="subheading">{page?.template.title}</p>
            <div className="btn-group">
              <a className="launch-button btn-primary-blue" rel="noreferrer" target="_blank" href={page?.link}>
                {ButtonText.Launch} {!page?.registerlink && <IconRight />}
              </a>
              {page?.registerlink && (
                <a
                  className="launch-button btn-primary-blue"
                  rel="noreferrer"
                  target="_blank"
                  href={page?.registerlink}
                >
                  {ButtonText.Register}
                </a>
              )}
            </div>
          </Col>
        </Row>
        <Row className={"flex-column"}>
          {/* <Col className="about-page">
            <h3>
              <span className={"title"}>{ 'About ' + page?.name}</span>
            </h3>
          </Col> */}
          <Col className="description">
            {page?.template.description.split("\n").map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="list">
            {!ServicesWithoutExpandibleSections.includes(page?.name.toLocaleLowerCase()) && (
              <AccordionList data={page?.template.accordion as AccordionItem[]} />
            )}
          </Col>
        </Row>
      </Col>
      <Col xs={1} />
      <Col style={{ position: "relative" }}>
        {Array.isArray(page?.template.images) && (page?.template?.images?.length || 0) > 0 && (
          <Switcher useFixed={true} images={page?.template.images as string[]} />
        )}
      </Col>
    </Row>
  );
};
