import { ReactNode, useEffect, useRef, useState } from "react";
import { CarouselNext, CarouselPrev } from "../GlobalIcons";

export type CustomCarouselProps = {
  children: ReactNode;
  singleBoxWidth?: number;
  minWidth?: number;
};

export const calculateVisibleBoxes = (carouselWidth: number, singleBoxWidth: number): number => {
  return Math.floor(carouselWidth / singleBoxWidth);
};

const DefaultSingleBoxWidth = 260;

export const CustomCarousel = ({ children, singleBoxWidth, minWidth }: CustomCarouselProps) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const boxWidth = singleBoxWidth || DefaultSingleBoxWidth;
  const wrapperRef = useRef();

  const handlePrev = () => {
    setSlideIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const handleNext = () => {
    const numBoxes = (children as Array<any>).length;
    const visibleBoxes = calculateVisibleBoxes(carouselWidth, boxWidth);
    const remainingClicks = numBoxes - visibleBoxes - slideIndex;

    if (remainingClicks > 0) {
      setSlideIndex((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    const calculateSize = () => {
      if (wrapperRef && wrapperRef.current) {
        const rect = (wrapperRef.current as any)?.getBoundingClientRect?.();
        if (rect?.width) {
          setCarouselWidth(rect.width);
        }
      }
    };
    calculateSize();
    window.addEventListener("resize", calculateSize);
    return () => {
      window.removeEventListener("resize", calculateSize);
    };
  }, [wrapperRef]);

  const numBoxes = (children as Array<any>).length;
  const visibleBoxes = calculateVisibleBoxes(carouselWidth, boxWidth);
  const showButtons = numBoxes > visibleBoxes && slideIndex + visibleBoxes < numBoxes;
  const slidePercentage = (slideIndex * boxWidth * 100) / (numBoxes * boxWidth);

  const style: any = {};

  if (minWidth) {
    style.width = minWidth.toString() + "px";
  }

  return (
    <div className="carousel-container" style={style} ref={wrapperRef as any}>
      <div className="custom-carousel">
        {slideIndex > 0 && (
          <div className="button-holder-left">
            <button className="button-left" onClick={handlePrev}>
              <CarouselPrev />
            </button>
          </div>
        )}

        <div
          className="custom-carousel-wrapper"
          style={{
            width: carouselWidth,
          }}
        >
          <div
            className="carousel-items"
            style={{
              transform: `translateX(-${slidePercentage}%)`,
            }}
          >
            {(children as Array<any>)?.map((child, index) => (
              <div className="single-item" key={index}>
                {child}
              </div>
            ))}
          </div>
        </div>

        {showButtons && (
          <div className="button-holder-right">
            <button className="button-right" onClick={handleNext} disabled={slideIndex >= numBoxes - visibleBoxes}>
              <CarouselNext />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
